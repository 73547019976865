export default [
  {
    key: 'name',
    label: 'field.name',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'note',
    label: 'field.note',
    rules: 'max:200',
    type: 'textarea',
    cols: 12,
  },
  {
    key: 'isEnable',
    label: 'field.active',
    type: 'checkbox',
    cols: 'auto',
  },
]
